// fonts
@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins-bold';
  src: url('../src/assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins-l';
  src: url('../src/assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Playfair-bold';
  src: url('../src/assets/fonts/PlayfairDisplay-Bold.ttf');
}

@import "primeng/resources/themes/lara-light-teal/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";
@import "quill/dist/quill.snow.css";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";

body {
  margin: 0;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-ground);
  font-family: var(--font-family), serif;
  font-weight: 400;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

a {
  text-decoration: none;
}

.page-wrapper {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 10px;
}

.p-card.p-0 {
  border-radius: 15px;
  overflow: hidden;

  .p-card-content, > .p-card-body {
    padding: 0;
  }

  .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 0.75rem 1.25rem;
  }

  .p-card-title {
    padding: 15px 15px;
  }
}

.p-fluid .p-inputtext {
  width: 100%;
}

label {
  &.required {
    &::after {
      content: '*';
      color: red;
    }
  }
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.175rem 0;
}

.p-inputtext:disabled, .p-inputtext.p-disabled {
  background-color: #e9ecef;
}

.p-button:focus {
  box-shadow: none !important;
}

.p-inputtext.ng-invalid.ng-touched:not(.p-filled) {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

p-chips.ng-invalid.ng-touched:not(.p-filled), p-dropdown.ng-invalid.ng-touched:not(.p-inputwrapper-filled) {
  .p-dropdown {
    border-color: #dc3545;
  }

  .p-inputtext {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }
}

textarea.p-inputtext.ng-invalid.ng-touched:not(.p-filled) {
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
}

code {
  &.text {
    background-color: var(--primary-100);
    color: var(--primary-900);
    border-radius: 6px;
    padding: 2px 6px;
    font-size: .775rem;
    font-weight: 600;
    font-style: normal;
  }
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: none;
}

.p-card {
  border-radius: 15px;
}

td .p-tag {
  text-wrap: nowrap;
}

.p-multiselect .p-multiselect-label {
  padding: 0.6rem 0.6rem;
}

.app-timeline {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 15px;
    bottom: 0;
    z-index: 0;
    border-left: 2px solid var(--surface-400);
  }

  .app-timeline-item {
    position: relative;
  }

  .app-timeline-header {
    margin: 1rem 0;
  }

  .app-timeline-content {
    margin-left: 3.5rem;
  }
}

.p-inputgroup-sm {
  .p-button.p-button-icon-only {
    padding: 0;
  }

  .p-inputtext {
    font-size: 0.875rem;
    padding: 0.65625rem 0.65625rem;
  }
}

.content-spinner {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
